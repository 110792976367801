"use strict";

import './google-tag-manager';
import 'bootstrap/js/dist/modal';
import 'bootstrap/scss/bootstrap.scss';
import 'styles/linguarobot.less';

const $ = require('jquery');
const formatJson = require("json-format-highlight");
const SmoothScroll = require("smooth-scroll");
const AlertMessage = require("./AlertMessage");

const ALERT_TIMEOUT = 5000;

const EXAMPLE_JSON = {
    "entries": [{
        "entry": "example",
        "pronunciations": [{
            "transcriptions": [{"transcription": "/ɪɡˈzæmpl̩/", "notation": "IPA"}],
            "audio": {
                "url": "http://audio.linguarobot.io/en/example-us.mp3",
                "sourceUrl": "https://commons.wikimedia.org/w/index.php?curid=267013"
            },
            "context": {"regions": ["United States"]}
        }, {
            "transcriptions": [{"transcription": "/ɪɡˈzɑːmpl̩/", "notation": "IPA"}],
            "context": {"regions": ["United Kingdom"]}
        }, {
            "transcriptions": [{"transcription": "/ɘɡˈzɐːmpɯ/", "notation": "IPA"}],
            "context": {"regions": ["New Zealand"]}
        }],
        "interpretations": [{
            "lemma": "example",
            "partOfSpeech": "noun",
            "grammar": [{"number": ["singular"], "case": ["nominative"]}],
            "normalizedLemmas": [{"lemma": "example"}]
        }, {
            "lemma": "example",
            "partOfSpeech": "verb",
            "grammar": [{
                "person": ["first-person", "second-person"],
                "number": ["singular"],
                "verbForm": ["finite"],
                "tense": ["present"],
                "mood": ["indicative"]
            }, {
                "person": ["first-person", "second-person", "third-person"],
                "number": ["plural"],
                "verbForm": ["finite"],
                "tense": ["present"],
                "mood": ["indicative"]
            }, {
                "person": ["first-person", "second-person", "third-person"],
                "number": ["singular", "plural"],
                "verbForm": ["finite"],
                "tense": ["present"],
                "mood": ["subjunctive"]
            }, {"verbForm": ["finite"], "mood": ["imperative"]}, {"verbForm": ["infinitive"]}],
            "normalizedLemmas": [{"lemma": "example"}]
        }],
        "lexemes": [{
            "lemma": "example",
            "partOfSpeech": "verb",
            "senses": [{"definition": "To be illustrated or exemplified (by)."}],
            "forms": [{
                "form": "examples",
                "grammar": [{
                    "person": ["third-person"],
                    "number": ["singular"],
                    "verbForm": ["finite"],
                    "tense": ["present"],
                    "mood": ["indicative"]
                }]
            }, {
                "form": "exampling",
                "grammar": [{"verbForm": ["participle"], "tense": ["present"]}]
            }, {"form": "exampling", "grammar": [{"verbForm": ["gerund"]}]}, {
                "form": "exampled",
                "grammar": [{
                    "person": ["first-person", "second-person", "third-person"],
                    "number": ["singular", "plural"],
                    "verbForm": ["finite"],
                    "tense": ["past"],
                    "mood": ["indicative"]
                }]
            }, {"form": "exampled", "grammar": [{"verbForm": ["participle"], "tense": ["past"]}]}]
        }, {
            "lemma": "example",
            "partOfSpeech": "noun",
            "senses": [{
                "definition": "Something that is representative of all such things in a group.",
                "labels": ["countable"]
            }, {
                "definition": "Something that serves to illustrate or explain a rule.",
                "labels": ["countable"]
            }, {
                "definition": "Something that serves as a pattern of behaviour to be imitated (a good example) or not to be imitated (a bad example).",
                "labels": ["countable"]
            }, {
                "definition": "A person punished as a warning to others.",
                "labels": ["countable"]
            }, {
                "definition": "A parallel or closely similar case, especially when serving as a precedent or model.",
                "labels": ["countable"]
            }, {
                "definition": "An instance (as a problem to be solved) serving to illustrate the rule or precept or to act as an exercise in the application of the rule.",
                "labels": ["countable"]
            }],
            "forms": [{"form": "examples", "grammar": [{"number": ["plural"], "case": ["nominative"]}]}]
        }],
        "license": {"name": "CC BY-SA 3.0", "url": "https://creativecommons.org/licenses/by-sa/3.0"},
        "sourceUrls": ["https://en.wiktionary.org/wiki/example"]
    }]
};

$(document).ready(() => {
    initCurrentYearPlaceholder();
    initDemoSection();
    initSmoothScroll();
    initNavigationBar();
    initEmailSubscription();
});

function initCurrentYearPlaceholder() {
    let now = new Date();
    $('.current-year-placeholder').text(now.getFullYear());
}

function initSmoothScroll() {
    new SmoothScroll(".inner-link");
}

function initNavigationBar() {
    $('.nav-container .bar[data-scroll-class*="fixed"]:not(.bar--absolute)').each((i, e) => {
        let bar = $(e);
        let barHeight = bar.outerHeight(true);
        bar.closest('.nav-container').css('min-height', barHeight);
    });
}

function initDemoSection() {
    let section = $("#tryit");
    let responseContainer = section.find("#response-container");
    responseContainer.find("pre").html(formatJson(EXAMPLE_JSON));
    section.find("form").submit(event => {
        event.preventDefault();
        responseContainer.addClass("loading");
        let form = $(event.currentTarget);
        let entry = encodeURIComponent(form.find("input#entry").val());
        let request = {
            "async": true,
            "crossDomain": true,
            "method": "GET",
            "url": "https://api.linguarobot.io/language/v1/entries/en/" + entry,
            "headers": {
                "Accept": "application/json"
            }
        };

        $.getJSON(request).done(response => {
            responseContainer.find("pre").html(formatJson(response));
            responseContainer.removeClass("loading");
        });
    })
}

function initEmailSubscription() {
    let body = $('body');
    let alertArea = $('<div/>').appendTo(body).addClass('alert-area').hide();
    let alertMessage = new AlertMessage(alertArea);

    $(document).on('change input paste keyup', '.attempted-submit input.field-error', event => $(event.currentTarget).removeClass('field-error'));
    $(document).on('change input paste keyup', '.attempted-submit .field-error input', event => $(event.currentTarget).parent().removeClass('field-error'));

    $('.form-email-subscription')
        .attr('novalidate', true)
        .submit(event => {
            event.preventDefault();
            let form = $(event.currentTarget).closest('form').addClass('attempted-submit');
            let email = form.find('input[name="EMAIL"]').val();
            if (validateFormInput(form, alertMessage)) {
                form.removeClass('attempted-submit');
                let button = form.find('button[type="submit"], input[type="submit"]');
                button.addClass('btn--loading');
                try {
                    $.ajax({
                        url: "https://gmail.us20.list-manage.com/subscribe/post-json?u=a92cf0dee3f9cfa4402c788d6&id=eef267da7d&c=?",
                        crossDomain: true,
                        data: form.serialize(),
                        method: "GET",
                        dataType: 'jsonp',
                        error: response => {
                            button.removeClass('btn--loading');
                            alertMessage.show('error', "Could not connect to the registration server. Please try again later.", ALERT_TIMEOUT);
                        },
                        success: response => {
                            button.removeClass('btn--loading');
                            if (response.result === 'success') {
                                form.get(0).reset();
                                form.find('.input-radio, .input-checkbox').removeClass('checked');
                                let message = form.attr('data-success') ? form.attr('data-success') : response.msg;
                                alertMessage.show('success', message, ALERT_TIMEOUT);
                            } else {
                                // It seems there's no better way to find out what type of error it is
                                if (response.msg.includes('is already subscribed to list')) {
                                    alertMessage.show('error', `A recipient with e-mail "${email}" is already subscribed to the updates`, ALERT_TIMEOUT);
                                } else {
                                    alertMessage.show('error', response.msg, ALERT_TIMEOUT);
                                }
                            }
                        }
                    });
                } catch (error) {
                    button.removeClass('btn--loading');
                    alertMessage.show('error', error.message, ALERT_TIMEOUT);
                }
            }
        });
}

function validateFormInput(form, alertMessage) {
    form.find('.validate-required[type="checkbox"]')
        .each((index, element) => {
            let checkbox = $(element);
            if (!checkbox.is(':checked')) {
                checkbox.parent().addClass('field-error');
            } else {
                checkbox.parent().removeClass('field-error');
            }
        });

    form.find('.validate-required, .required, [required]').not('input[type="checkbox"]')
        .each((index, element) => {
            let input = $(element);
            if (input.val() === '') {
                input.addClass('field-error');
            } else {
                input.removeClass('field-error');
            }
        });

    form.find('.validate-email, .email, [name*="cm-"][type="email"]')
        .each((index, element) => {
            let emailInput = $(element);
            let isValidEmail = /(.+)@(.+){2,}\.(.+){2,}/.test(emailInput.val());
            if (!isValidEmail) {
                emailInput.addClass('field-error');
            } else {
                emailInput.removeClass('field-error');
            }
        });

    let isValid = !form.find('.field-error').length;

    if (!isValid) {
        let firstErrorInput = form.find('.field-error:first');
        if (firstErrorInput.length) {
            $('html, body').animate({scrollTop: (firstErrorInput.offset().top - 100)}, 1200);
        }

        let errorMessage = form.attr('data-error') ? form.attr('data-error') : "Please fill all fields correctly";
        alertMessage.show('error', errorMessage);
    }

    return isValid;
}





