const $ = require('jquery');

module.exports = class AlertMessage {

    constructor(container) {
        this.container = $(container);
        this.timeout = null;
    }

    hide(duration, callback) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
            this.container.fadeOut(duration, () => {
                this.container.removeClass('alert-success alert-error');
                this._call(callback);
            });
        } else {
            this._call(callback);
        }
    };

    show(status, message, timeout) {
        this.hide(200, () => {
            this.container.text(message).addClass('alert-' + status).fadeIn();
            this.timeout = setTimeout(this.hide.bind(this), timeout | 5000);
        });

    };

    _call(fn) {
        if (typeof fn === 'function') {
            fn();
        }
    }

};